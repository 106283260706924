"use client"
import React, { ReactNode, useState, useCallback, useRef, useEffect } from "react";
import { usePathname } from "next/navigation";
import NProgress from "nprogress";
import RouterProgressionContext from '@/contexts/RouterProgressionContext';
import NavigationProgressBar from "./NavigationProgressBar";

// declare global {
//     interface Window {
//         intercomSettings: any;
//         Intercom: any;
//         gtagSettings: any;
//         dataLayer: any[];
//         gtag: any;
//     }
// }

/**
 ** Inspiration from here
 ** The original Router component from Next.js no longer has router events.
 ** More discussion here
 @link https://github.com/vercel/next.js/discussions/41745#discussioncomment-3986452
 @link https://github.com/vercel/next.js/discussions/41745#discussioncomment-4202641
 @link https://github.com/joulev/nextjs13-router-events/blob/main/app/Anchor.client.tsx
*/
export default function RouterEventWrapper({ children }: { children: ReactNode }) {
    const onStart = useCallback(() => NProgress.start(), []);
    const onComplete = useCallback(() => NProgress.done(), []);
    const [isChanging, setIsChanging] = useState(false);
    const pathname = usePathname();
    const isAppPath = /^\/(.*\/)?app/.test(pathname);
    const onCompleteFresh = useRef(onComplete);
    const onStartFresh = useRef(onStart);
    useEffect(() => setIsChanging(false), [pathname]);

    useEffect(() => {
        if (isChanging) onStartFresh.current();
        else onCompleteFresh.current();
    }, [isChanging]);

    /* Weglot */
    // useEffect(() => {
    //   const script = document.createElement('script');
    //   script.src = 'https://cdn.weglot.com/weglot.min.js';
    //   script.onload = function () {
    //     if (window.Weglot) {
    //       window.Weglot.initialize({
    //         api_key: 'wg_ac8fa753bafeb3045191cf67690031f72'
    //       });
    //     }
    //   };
    //   document.head.appendChild(script);

    //   const alternateEn = document.createElement('link');
    //   alternateEn.rel = 'alternate';
    //   alternateEn.hreflang = 'en';
    //   alternateEn.href = 'https://www.okgrow.com';
    //   document.head.appendChild(alternateEn);

    //   const alternateEs = document.createElement('link');
    //   alternateEs.rel = 'alternate';
    //   alternateEs.hreflang = 'es';
    //   alternateEs.href = 'https://es.okgrow.com';
    //   document.head.appendChild(alternateEs);
    // }, []);

    /* Intercom */
    useEffect(() => {
        if (typeof window === 'undefined' || isAppPath) return;
        (window as any).intercomSettings = {
            app_id: 'uucr3lhq',
            background_color: '#ff006f',
            // email: 'test@test.com',
        }
        function setupIntercom(t: { app_id: string; background_color: any; custom_launcher_selector: any; }) {
            var e = "undefined" !== t.app_id ? t.app_id : "";
            if (e) {
                var n = void 0 !== t.background_color ? t.background_color : "#FFFFFF",
                    o = function (t, e = null, n = null) {
                        var o = document.createElement("div");
                        return Object.keys(t).forEach((function (e) {
                            o.style[e] = t[e]
                        })), e && o.setAttribute("id", e), o.innerHTML = n, o
                    },
                    i = function (t) {
                        if (!window.Intercom) {
                            var n = window,
                                o = n.Intercom;
                            if ("function" == typeof o) o("reattach_activator"), o("update", window.intercomSettings);
                            else {
                                var i = document;
                                var l = {
                                    q: [],
                                    c: function (t) {
                                        this.q.push(t);
                                    }
                                };
                                n.Intercom = l;
                                var c = function () {
                                    var t = i.createElement("script");
                                    t.type = "text/javascript", t.async = !0, t.src = "https://widget.intercom.io/widget/" + e + "/";
                                    var n = i.getElementsByTagName("script")[0];
                                    n.parentNode.insertBefore(t, n)
                                }
                            }
                            c()
                        }
                        if (typeof window.Intercom === 'function') {
                            t && (r.style.opacity = "0", a.style.opacity = "1", a.style.transform = "rotate(0deg)", window.Intercom("show"));
                        }
                        var s = 0,
                            d = setInterval((function () {
                                s++, window.Intercom.booted ? (null !== document.querySelector("#intercom-facade-btn") && document.querySelector("#intercom-facade-btn").remove(), clearInterval(d)) : s > 10 && clearInterval(d)
                            }), 1e3);
                        return !0
                    },
                    r = o({
                        display: "flex",
                        WebkitBoxAlign: "center",
                        alignItems: "center",
                        WebkitBoxPack: "center",
                        justifyContent: "center",
                        position: "absolute",
                        top: "0px",
                        bottom: "0px",
                        width: "100%",
                        transform: "rotate(0deg) scale(1)",
                        transition: "transform 0.16s linear 0s, opacity 0.08s linear 0s"
                    }, null, '\n<svg\n  height="32px"\n  width="28px"\n  focusable="false"\n  aria-hidden="true"\n  viewBox="0 0 28 32">\n  <path\n    fill="rgb(255, 255, 255)"\n    d="M28,32 C28,32 23.2863266,30.1450667 19.4727818,28.6592 L3.43749107,28.6592 C1.53921989,28.6592 0,27.0272 0,25.0144 L0,3.6448 C0,1.632 1.53921989,0 3.43749107,0 L24.5615088,0 C26.45978,0 27.9989999,1.632 27.9989999,3.6448 L27.9989999,22.0490667 L28,22.0490667 L28,32 Z M23.8614088,20.0181333 C23.5309223,19.6105242 22.9540812,19.5633836 22.5692242,19.9125333 C22.5392199,19.9392 19.5537934,22.5941333 13.9989999,22.5941333 C8.51321617,22.5941333 5.48178311,19.9584 5.4277754,19.9104 C5.04295119,19.5629428 4.46760991,19.6105095 4.13759108,20.0170667 C3.97913051,20.2124916 3.9004494,20.4673395 3.91904357,20.7249415 C3.93763774,20.9825435 4.05196575,21.2215447 4.23660523,21.3888 C4.37862552,21.5168 7.77411059,24.5386667 13.9989999,24.5386667 C20.2248893,24.5386667 23.6203743,21.5168 23.7623946,21.3888 C23.9467342,21.2215726 24.0608642,20.9827905 24.0794539,20.7254507 C24.0980436,20.4681109 24.0195551,20.2135019 23.8614088,20.0181333 Z"\n    />\n</svg>\n'),
                    a = o({
                        display: "flex",
                        WebkitBoxAlign: "center",
                        alignItems: "center",
                        WebkitBoxPack: "center",
                        justifyContent: "center",
                        position: "absolute",
                        top: "0px",
                        bottom: "0px",
                        width: "100%",
                        transition: "transform 0.16s linear 0s, opacity 0.08s linear 0s",
                        opacity: "0",
                        transform: "rotate(-30deg)"
                    }, null, '\n<svg focusable="false" viewBox="0 0 16 14" width="28" height="25" style="width: 16px;">\n  <path\n    fill="rgb(255, 255, 255)"\n    fillRule="evenodd"\n    clipRule="evenodd"\n    d="M.116 4.884l1.768-1.768L8 9.232l6.116-6.116 1.768 1.768L8 12.768.116 4.884z"\n  />\n</svg>\n'),
                    l = o({
                        position: "absolute",
                        top: "0px",
                        left: "0px",
                        width: "60px",
                        height: "60px",
                        borderRadius: "50%",
                        cursor: "pointer",
                        transformOrigin: "center",
                        overflowX: "hidden",
                        overflowY: "hidden",
                        WebkitBackfaceVisibility: "hidden",
                        WebkitFontSmoothing: "antialiased"
                    }),
                    c = o({
                        fontFamily: "intercom-font, 'Helvetica Neue', 'Apple Color Emoji', Helvetica, Arial, sans-serif",
                        fontSize: "100%",
                        fontStyle: "normal",
                        letterSpacing: "normal",
                        fontStretch: "normal",
                        fontVariantLigatures: "normal",
                        fontVariantCaps: "normal",
                        fontVariantEastAsian: "normal",
                        fontVariantPosition: "normal",
                        fontWeight: "normal",
                        textAlign: "left",
                        textDecorationLine: "none",
                        textDecorationStyle: "initial",
                        textDecorationColor: "initial",
                        textDecoration: "none",
                        textIndent: "0px",
                        textShadow: "none",
                        textTransform: "none",
                        boxSizing: "content-box",
                        WebkitTextEmphasisStyle: "none",
                        WebkitTextEmphasisColor: "initial",
                        WebkitFontSmoothing: "antialiased",
                        lineHeight: 1
                    }),
                    s = o({
                        zIndex: 2147483004,
                        transform: "scale(0.8) translateX(8px) translateY(8px)",
                        position: "fixed",
                        bottom: "20px",
                        display: "block",
                        right: "20px",
                        width: "60px",
                        height: "60px",
                        borderRadius: "50%",
                        boxShadow: "rgba(0, 0, 0, 0.0588235) 0px 1px 6px 0px, rgba(0, 0, 0, 0.156863) 0px 2px 32px 0px",
                        backgroundColor: n
                    }, "intercom-facade-btn");
                l.append(r), l.append(a), c.append(l), c.addEventListener("click", (function () {
                    i(!0)
                })), c.addEventListener("mouseenter", (function () {
                    i(!1)
                })), s.append(c), document.querySelector("body").append(s), void 0 !== t.custom_launcher_selector && document.querySelectorAll(t.custom_launcher_selector).forEach((function (t) {
                    t.addEventListener("click", (function (t) {
                        t.preventDefault(), i(!0)
                    }))
                }))
            }
        } (window.intercomSettings);
        setupIntercom(window.intercomSettings);

        function triggerIntercomLoading() {
            // Lazy load Intercom after 8 seconds
            const timer = setTimeout(() => {
                if (document.getElementById('intercom-facade-btn')) {
                    var event = new MouseEvent('mouseenter', { 'view': window, 'bubbles': true, 'cancelable': true });
                    document.getElementById('intercom-facade-btn').children[0].dispatchEvent(event);
                }
            }, 8000);
            return () => clearTimeout(timer); // cleanup on unmount
        }

        if ('requestIdleCallback' in window) {
            window.requestIdleCallback((deadline) => {
                triggerIntercomLoading()
            });
        } else
            triggerIntercomLoading()
    }, [isAppPath]);

    // Global site tag (gtag.js) - Google Analytics
    useEffect(() => {
        if (typeof window === 'undefined') return;
        (window as any).gtagSettings = {
            id: 'AW-782574821',
            config: ['AW-782574821',
                //  'G-SZTEJZ3ZG7' //TODO: Add google analytics ID here
            ]
        }
        function setupGtag(t: { id: string; config: string[]; }) {
            var e = "undefined" !== t.id ? t.id : "";
            if (e) {
                var n = t.config || [],
                    o = function (t, e = null, n = null) {
                        // Check if gtag script has already been added
                        if (!document.querySelector(`script[src="https://www.googletagmanager.com/gtag/js?id=${e}"]`)) {
                            var o = document.createElement("script");
                            o.async = true;
                            o.src = "https://www.googletagmanager.com/gtag/js?id=" + e;
                            setTimeout(() => { // Delay the loading of gtag manager js by 4 seconds
                                document.head.appendChild(o);
                            }, 4000);
                        }
                        window.dataLayer = window.dataLayer || [];
                        function gtag(..._args: any[]) { window.dataLayer.push(_args); }
                        gtag('js', new Date());
                        n.forEach((config) => {
                            gtag('config', config);
                        });
                    }
                o(t, e, n);
            }
        }
        setupGtag(window.gtagSettings);
    }, []);

    // Set Google Ads Conversion ID to cookies
    const setCookie = useCallback((e, t, n) => { const o = new Date(); o.setTime(o.getTime() + 24 * n * 60 * 60 * 1e3); const r = "; expires=" + o.toUTCString(); document.cookie = e + "=" + t + r + ";path=/"; }, []);
    const getParam = useCallback((e) => { const t = RegExp("[?&]" + e + "=([^&]*)").exec(window.location.search); return t && decodeURIComponent(t[1].replace(/\+/g, " ")); }, []);
    useEffect(() => {
        if (typeof window !== 'undefined') {
            if (getParam('gclid')) setCookie('gclid', `google_${getParam('gclid')}`, 90);
            else if (getParam('wbraid')) setCookie('gclid', `google_${getParam('wbraid')}`, 90);
            else if (getParam('gbraid')) setCookie('gclid', `google_${getParam('gbraid')}`, 90);
            else if (getParam('ttclid')) setCookie('ttclid', `tiktok_${getParam('ttclid')}`, 90);
        }
    }, [setCookie, getParam]);

    // TikTok Pixel
    // useEffect(() => {
    //     if (typeof window === 'undefined' || typeof document === 'undefined') return;
    //     setTimeout(() => {
    //         (function (w, d, t) {
    //             if (typeof w !== 'undefined') {
    //                 (w as any).TiktokAnalyticsObject = t;
    //                 var ttq = (w as any)[t] = (w as any)[t] || [];
    //                 ttq.methods = ["page", "track", "identify", "instances", "debug", "on", "off", "once", "ready", "alias", "group", "enableCookie", "disableCookie"];
    //                 ttq.setAndDefer = function (t, e) {
    //                     t[e] = function (...args) { // Use rest parameters here
    //                         t.push([e].concat(args)) // args is already an array, so you can use it directly
    //                     }
    //                 };
    //                 for (var i = 0; i < ttq.methods.length; i++) ttq.setAndDefer(ttq, ttq.methods[i]);
    //                 ttq.instance = function (t) {
    //                     for (var e = ttq._i[t] || [], n = 0; n < ttq.methods.length; n++) ttq.setAndDefer(e, ttq.methods[n]);
    //                     return e
    //                 };
    //                 ttq.load = function (e, n) {
    //                     var i = "https://analytics.tiktok.com/i18n/pixel/events.js";
    //                     ttq._i = ttq._i || {};
    //                     ttq._i[e] = [];
    //                     ttq._i[e]._u = i;
    //                     ttq._t = ttq._t || {};
    //                     ttq._t[e] = +new Date;
    //                     ttq._o = ttq._o || {};
    //                     ttq._o[e] = n || {};
    //                     var o = document.createElement("script");
    //                     o.type = "text/javascript";
    //                     o.async = true;
    //                     o.src = i + "?sdkid=" + e + "&lib=" + t;
    //                     var a = document.getElementsByTagName("script")[0];
    //                     a.parentNode.insertBefore(o, a)
    //                 };
    //                 ttq.load('CN9RGOBC77U2LHT34980');
    //                 ttq.page();
    //             }
    //         })(window, document, 'ttq');
    //     }, 4000);
    // }, []);


    return (
        <RouterProgressionContext.Provider value={() => setIsChanging(true)}>
            <NavigationProgressBar />
            {children}

            {/* Tolt.io Affiliate Script */}
            {/* <Script id="tolt-script" async src="https://cdn.tolt.io/tolt.js" data-tolt="5467aa2d-3bb3-4f0c-9956-226e82f39ac8" strategy='lazyOnload'></Script> */}

            {/* Meta Pixel */} 
            {/* {process.env.NODE_ENV === 'production' && (
                <>
                    <Script strategy="lazyOnload" async id="meta-pixel" dangerouslySetInnerHTML={{
                        __html: `
                            !function(f,b,e,v,n,t,s)
                            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                            n.queue=[];t=b.createElement(e);t.async=!0;
                            t.src=v;s=b.getElementsByTagName(e)[0];
                            s.parentNode.insertBefore(t,s)}(window, document,'script','https://connect.facebook.net/en_US/fbevents.js');
                            fbq('init', '353141740953135');
                            fbq('track', 'PageView');
                    `}} />
                    <noscript><img height="1" width="1" style={{ display: "none" }} src="https://www.facebook.com/tr?id=353141740953135&ev=PageView&noscript=1" /></noscript>
                </>
            )} */}

        </RouterProgressionContext.Provider>
    );
}